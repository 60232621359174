<template>
  <div>
    <Dialog
      v-if="visible"
      @close="closeEntityDialog"
      :width="dialogProps.width"
      :height="dialogProps.height"
    >
      <div class="dialog-body">
        <div class="dialog-container">
          <div class="entity-table">
            <table>
              <tr>
                <th v-for="(headerName, index) of headers" :key="index">
                  <div class="header-block">
                    <p>{{ headerName }}</p>
                  </div>
                </th>
              </tr>
              <tr v-for="(entity, index) in entityList" :key="index">
                <CorporateActionItemRow
                  :entity="entity"
                  :masterEntity="masterEntity"
                  @markAsMaster="updateMarkAsMaster"
                  @unSubscribe="updateUnSubscribe"
                >
                </CorporateActionItemRow>
              </tr>
            </table>
            <div class="row">
              <SearchableSelect
                :value="reasonCode"
                label="Corporate Action Reason Code"
                :required="true"
                :placeholder="'Select Corporate Action Reason Code'"
                value_field="value"
                text_field="text"
                :isMandatory="true"
                class="form-input-field searchable-select"
                @onChangeName="updateReasonCode"
                :submitted="submitted"
                :mandatorySubmit="submitted"
                :isLoading="isEntityDeclineReasonsLoading"
                :data="caDeclinedReasons"
              />
            </div>
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <div class="action-buttons">
          <b-button
            class="action-button cancel-button"
            @click="closeEntityDialog"
          >
            Cancel
          </b-button>
          <b-button
            class="action-button confirm-button"
            :disabled="isConfirmBtnDisabled"
            @click="handleConfirm"
            :loading="isLoading"
          >
            Confirm
          </b-button>
        </div>
      </DialogActionsBar>
    </Dialog>
  </div>
</template>

<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import { mapActions, mapState } from 'vuex'
import CorporateActionItemRow from '@/components/DMP/CorporateActionItemRow.vue'
import SearchableSelect from '@/components/DMP/SearchableSelect'
import { isNull } from 'lodash'

export default {
  name: 'CorporateActionDialog',
  components: {
    Dialog,
    DialogActionsBar,
    CorporateActionItemRow,
    SearchableSelect
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    closeDialog: {
      type: Function,
      required: true
    },
    isFromPMDashboard: {
      type: Boolean,
      default: false
    },
    isPMRole: {
      type: Boolean,
      default: false
    },
    entityList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      dialogProps: {
        width: 1050,
        height: 600
      },
      headers: [
        'SGA ID',
        'Request ID',
        'Legal Entity Name',
        'Country of Domicile',
        // 'Coundtry of Registry',
        'Mark as Master',
        'Unsubscribe'
      ],

      reasonCode: null,
      submitted: false,
      corporateActionEntityList: [],
      masterEntity: null,
      isConfirmBtnDisabled: true,
      unSubscribeIds: new Set(),
      isLoading: false
    }
  },
  computed: {
    ...mapState('pmDashboard', [
      'caDeclinedReasons',
      'isEntityDeclineReasonsLoading'
    ])
  },
  watch: {
    entityList: {
      handler (newList) {
        this.corporateActionEntityList = [...newList]
      },
      immediate: true,
      deep: true
    },

    masterEntity() {
      if (isNull(this.masterEntity)) {
        this.isConfirmBtnDisabled = true
      } else {
        this.isConfirmBtnDisabled = false
      }
    }
  },
  async mounted() {
    this.setDialogSize()
    await this.getDeclinedReasons('CA')
    this.reasonCode = null
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setDialogSize)
  },
  methods: {
    ...mapActions('pmDashboard', [
      'getDeclinedReasons',
      'updateCorporateAction'
    ]),

    async handleConfirm() {
      this.submitted = true
      if (isNull(this.reasonCode) || isNull(this.masterEntity)) {
        return
      }
      this.isLoading = true
      const corporateEntities = await this.createPayload()

      const isSucess = await this.updateCorporateAction(corporateEntities)

      if (isSucess) {
        this.submitted = false
        this.closeEntityDialog()
      }
      this.isLoading = false
    },

    updateReasonCode(reasonCode) {
      this.reasonCode = reasonCode
    },

    updateMarkAsMaster(sgaId) {
      this.masterEntity = sgaId
      this.corporateActionEntityList = this.corporateActionEntityList.map(
        (entity) => {
          return { ...entity, master: this.masterEntity }
        }
      )
    },

    updateUnSubscribe({ sgaId, isUnSubscribed }) {
      if (isUnSubscribed) {
        this.unSubscribeIds.add(sgaId)
      } else {
        this.unSubscribeIds.delete(sgaId)
      }
    },

    createPayload() {
      this.corporateActionEntityList.forEach((entity) => {
        if (!entity.isSubscribed) {
          this.unSubscribeIds.add(entity.sgaId)
        }
      })

      const corporateEntities = {
        masterSGAId: this.masterEntity,
        unsubscribedSgaIds: Array.from(this.unSubscribeIds),
        declineId: this.reasonCode?.value
      }
      return corporateEntities
    },

    resetEntityDialogDetails() {
      this.reasonCode = null
      this.masterEntity = null
      this.unSubscribeIds = new Set() // Reset Set
      this.submitted = false
      this.$emit('resetSelection', true)
    },

    closeEntityDialog() {
      this.resetEntityDialogDetails()
      this.closeDialog()
    },

    setDialogSize() {
      const windowWidth = window.innerWidth
      const mediumWidth = 1450
      const smallWidth = 1000

      if (windowWidth > mediumWidth) {
        this.dialogProps.width = 1050
      } else if (windowWidth > mediumWidth) {
        this.dialogProps.width = 1050
      } else if (windowWidth > smallWidth) {
        this.dialogProps.width = 900
      } else {
        this.dialogProps.width =
          (1050 * (windowWidth - smallWidth)) / (mediumWidth - smallWidth) +
          1050
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-body {
  padding: 1.875rem;

  .entity-table {
    margin: 10px 0px 20px;
    background: var(--primary);
    border-radius: 4px;
    padding: 10px 20px;

    table {
      font-family: Quicksand;
      border-collapse: collapse;
      width: 100%;
    }

    th {
      text-align: left;
      padding: 8px;
      font-family: Quicksand;
      color: var(--secondary-text-color);
      font-weight: 500;

      .header-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
        color: #7d7d7d;
      }
    }

    tr {
      border-bottom: 1px solid var(--voc-bar-background-color);
    }

    tr:last-child {
      border-bottom: none;
    }
  }

  .row {
    margin-top: 30px;
    margin-right: 50%;
  }
}

::v-deep .k-dialog-actions {
  border: none;
}

::v-deep .k-window-actions {
  border: none;
}

::v-deep .k-dialog-content {
  overflow: auto;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  height: 5rem;
  padding-bottom: 0.6rem;
  width: 100%;
}

.action-button {
  color: #424242;
  font-size: 16px;
  font-weight: 600;
  min-width: 104px;
  height: 46px;
}

.confirm-button {
  background-color: #535eeb;
  /* Color of the background */
  background-position-x: 0%;
  /* Horizontal position of the background image */
  background-position-y: 0%;
  /* Vertical position of the background image */
  background-repeat: no-repeat;
  /* Background image will not repeat */
  background-origin: padding-box;
  /* Background painting area is the padding box */
  border-radius: 8px;
  padding: 8px;
  opacity: 1;
  text-align: left;
  font-size: 16px;
  line-height: 17px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-left: 1rem;
}

.confirm-button:hover {
  background-color: #00218a;
  /* Color of the background */
  background-position-x: 0%;
  /* Horizontal position of the background image */
  background-position-y: 0%;
  /* Vertical position of the background image */
  background-repeat: no-repeat;
  /* Background image will not repeat */
  background-origin: padding-box;
  /* Background painting area is the padding box */

  opacity: 0.9;
}

.cancel-button {
  background-color: #cccccc;
  /* Color of the background */
  background-position-x: 0%;
  /* Horizontal position of the background image */
  background-position-y: 0%;
  /* Vertical position of the background image */
  background-repeat: no-repeat;
  /* Background image will not repeat */
  background-origin: padding-box;
  /* Background painting area is the padding box */

  border-radius: 8px;
  padding: 8px;
  opacity: 1;
  text-align: center;
  font-size: 16px;
  line-height: 17px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #444444;
  opacity: 1;
}

.cancel-button:hover {
  background-color: #cccccc;
  /* Color of the background */
  background-position-x: 0%;
  /* Horizontal position of the background image */
  background-position-y: 0%;
  /* Vertical position of the background image */
  background-repeat: no-repeat;
  /* Background image will not repeat */
  background-origin: padding-box;
  /* Background painting area is the padding box */
  opacity: 0.9;
}

@media (max-width: 865px) {
  .action-button {
    font-size: 14px;
  }

  .confirm-button {
    text-align: left;
    font-size: 14px;
    line-height: 23px;
  }

  .cancel-button {
    text-align: center;
    font-size: 14px;
    line-height: 23px;
  }

  ::v-deep .k-dialog-content {
    overflow: auto;
  }
}

@media (min-width: 1700px) {
  .dialog-body {
    .entity-table {
      th {
        .header-block {
          font-size: 16px;
        }
      }
    }
  }

  .action-button {
    font-size: 18px;
  }

  .confirm-button {
    text-align: left;
    font-size: 18px;
    line-height: 23px;
  }

  .cancel-button {
    text-align: center;
    font-size: 18px;
    line-height: 23px;
  }
}
</style>
