<template>
  <div class="dmp-multiselect">
    <label class="typo__label"
      >{{ label }} <span class="isMandatory" v-if="isMandatory">*</span></label
    >
    <multiselect
      v-model="value"
      :options="options"
      placeholder="Type to search and select"
      @select="handleSelect"
      @open="handleDropdown('open')"
      @close="handleDropdown('close')"
      :title="hoverLabel"
      label="text"
      ref="DMPMultiselect"
      :show-labels="false"
      :internal-search="true"
      :preserve-search="true"
      @search-change="asyncFind"
      track-by="text"
      :loading="isLoading"
      :show-no-results="showNoDataFound && !isLoading">
      <template slot="noResult">
        <div class="data-not-found-container">
          <div class="text">
            <p>{{ notFoundMessage }}</p>
          </div>
          <div v-if="showAddEntityButton" class="add-button">
            <b-button
              size="is-small"
              icon-left="plus"
              @click="onAddEntityClick()">Add Entity</b-button>
          </div>
        </div>
      </template>
      <template slot="clear" slot-scope="props">
        <span class="close-icon" v-if="value" @click="remove()"> X </span>
      </template>
      <template slot="option" slot-scope="{ option }">
        <div
          @mouseover="handleOptionMouseOver(option)"
          @mouseleave="handleOptionMouseLeave(option)">
          {{ option.text }}
        </div>
      </template>
      <template v-slot:noOptions>
        <span>Type to search ...</span>
      </template>
    </multiselect>
    <div v-if="isMandatory && submitted && !value" class="required-error">
      {{ label }} is required
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'

export default {
  components: {
    Multiselect
  },
  props: {
    label: {
      type: String
    },
    field: {
      type: String
    },
    data: {
      type: Array
    },
    isMandatory: {
      type: Boolean
    },
    submitted: {
      type: Boolean
    },
    isButtonRequired: {
      type: Boolean
    },
    notFoundMessage: {
      type: String
    },
    setValue: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showAddEntityButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      value: null,
      hoverLabel: '',
      showNoDataFound: false
    }
  },
  computed: {
    options () {
      return this.data
    },
    ...mapState('relationship', ['isEntityListLoading'])
  },
  watch: {
    setValue() {
      if (this.setValue) {
        this.value = null
      }
    }
  },
  methods: {
    handleSelect (option) {
      this.selected = option
      this.$emit('onChange', this.selected)
      if (this.selected && this.isButtonRequired) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    remove () {
      this.value = null
      this.$emit('onChange', null)
    },
    onAddEntityClick () {
      this.$emit('addEntityClicked')
    },
    // debounce function with a 500ms debounce time
    asyncFind: debounce(async function (query) {
      this.showNoDataFound = false
      const searchQuery = query.trim()
      await this.$emit('getEntitySGADetails', searchQuery, this.field)
      this.showNoDataFound = true
    }, 500),
    handleDropdown(status) {
      if (status === 'open') {
        this.$emit('closeDropdownPopup', false)
      }
      // Scroll to the leftmost position when the dropdown opens or closes
      const dropdown = this.$refs.DMPMultiselect.$el.querySelector(
        '.multiselect__content-wrapper'
      )
      if (dropdown) {
        dropdown.scrollLeft = 0
      }
    },
    handleOptionMouseOver (option) {
      // Handle mouse over event on individual option
      this.hoverLabel = option.text
    },
    handleOptionMouseLeave  (option) {
      // Handle mouse over event on individual option
      this.hoverLabel = ''
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.content ul {
  margin-left: 0px;
  margin-top: 0px;
}
.dmp-multiselect {
  display: flex;
  flex-direction: column;
  width: 100%;

  .close-icon {
    position: absolute;
    width: 20px;
    height: 38px;
    right: 32px;
    padding: 11px 10px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
  }

  .required-error {
    color: red;
    position: relative;
    font-size: 12px;
  }
  .typo__label {
    text-align: left;
    font-size: 12px;
    margin-top: 0px;
    font-family: Quicksand;
    font-weight: 600;
    letter-spacing: 0px;
    color: #7d7d7d;
    margin-bottom: 5px;
    opacity: 1;
  }
  .isMandatory {
    color: red;
    font-size: 1rem;
  }
  .multiselect__placeholder {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    font-family: Quicksand;
    letter-spacing: 0.18px;
    color: #cccccc;
    opacity: 1;
  }

  .multiselect__tags {
    min-height: 36px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    font-family: Quicksand;
    letter-spacing: 0.18px;
    color: #cccccc;
    opacity: 1;
  }

  .multiselect__option {
    font-family: Quicksand;
    font-size: 13px;
    font-weight: 600;
    color: #1e1e1e;
  }
  .multiselect__single {
    font-family: Quicksand;
    font-size: 13px;
    font-weight: 600;
    width: 95%;
    color: #1e1e1e;
  }

  .data-not-found-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .text {
      text-align: left;
      font: 10px;
      letter-spacing: 0px;
      color: #e10d0d;
      opacity: 1;
    }
    .add-button {
      .button {
        background: #535eeb 0% 0% no-repeat padding-box;
        opacity: 1;
        text-align: left;
        font-size: 10px;
        font-weight: 500;
        font-family: Quicksand;
        letter-spacing: 0.18px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
}
@media only screen and (min-width: 1450px) and (max-width: 1700px) {
  .dmp-multiselect ::v-deep {
    .typo__label {
      font-size: 14px;
    }

    .multiselect__option {
      font-size: 14px;
    }
    .multiselect__single {
      font-size: 14px;
    }
  }
}
@media (min-width: 1700px) {
  .dmp-multiselect {
    .typo__label {
      font-size: 16px;
    }

    .multiselect__option {
      font-size: 16px;
    }
    .multiselect__single {
      font-size: 16px;
    }
  }
}
</style>
