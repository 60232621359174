<template>
  <div class="pm-dashboard-container">
    <div class="toolbar-container">
      <div class="toolbar-row">
        <!-- Add Entity Button -->
        <div class="toolbar-column">
          <b-button
            class="action-button add-entity-button"
            size="is-small"
            @click="handleShowEntityDialog"
          >
            <b-icon icon="plus" size="is-small" class="toolbar-icons"> </b-icon>
            &nbsp; Add Entity
          </b-button>
        </div>
      </div>
      <div v-if="true" class="toolbar-row">
        <!-- Bulk Assignment Button -->
        <div v-if="!isPMRole" class="toolbar-column">
          <b-button
            class="bulk-assignment-button"
            size="is-small"
            @click="handleBulkAssignment"
            @mouseover="bulkAssignmentTextPopup"
            @mouseleave="closeBulkAssignmentTextPopup"
          >
            <b-icon
              icon="list-box-outline"
              size="is-meidum"
              class="toolbar-icons-set-assignees"
            ></b-icon>
          </b-button>
          <div v-if="showBulkAssignmentTextPopup" class="text-popup">
            <p>Bulk Assignment</p>
          </div>
        </div>
        <!-- Select team -->
        <div v-if="!isPMRole" class="toolbar-column">
          <MultiSelectDialog
            :listData="teamList"
            @filterDashboardData="filterWithTeam"
            :isSelectAll="this.isTeamAllSelect"
            :dialogName="'Select Team'"
          />
        </div>

        <!-- Set Assignees Button -->
        <div class="toolbar-column">
          <MultiSelectDialog
            :listData="teamMemberList"
            @filterDashboardData="filterDashboardData"
            :isSelectAll="this.isTeamAllMemberSelect"
            :dialogName="'Select Assignee'"
          />
        </div>
        <!-- Assign Button -->
        <div class="toolbar-column">
          <div class="test">
            <SingleSelectDialog
              v-model="selectedTeamMemberId"
              :teamMemberList="teamMemberList"
              @updateField="handleAssignEntities"
            />
          </div>
        </div>

        <!-- Back To Pool Button -->
        <div class="toolbar-column">
          <b-button
            class="action-button back-to-pool-button"
            size="is-small"
            :disabled="loading"
            @click="handleBackToPool"
          >
            <b-icon
              icon="share"
              size="is-small"
              class="toolbar-icons toolbar-share-icon"
            >
            </b-icon>
            &nbsp; Back To Pool
          </b-button>
        </div>
        <!-- CA button -->
        <div class="toolbar-column">
          <b-button
            class="action-button add-entity-button"
            size="is-small"
            @click="handleCorporateActionDialog"
          >
            CA
          </b-button>
        </div>

        <!-- Upload Files  -->
        <div class="toolbar-column">
          <b-button
            class="action-button add-entity-button"
            size="is-medium"
            font-scale="6"
            @click="handleFileUploadDialog"
          >
            <b-icon icon="upload" size="is-small" class="toolbar-icons">
            </b-icon>
            &nbsp; Upload files
          </b-button>
        </div>
        <div class="toolbar-column">
          <SubmitAndReworkModal
            title="Submit"
            :selectedData="selectedForStaging"
            @isSubmitForDelivery="isSubmitForDelivery"
            @showConfirmationModalForSubmit="showConfirmationModalForSubmit"
            message="Are you sure you want to submit these entities to BNY?"
          ></SubmitAndReworkModal>
        </div>

        <div class="toolbar-column">
          <SubmitAndReworkModal
            title="Rework"
            :selectedData="selectedForRework"
            @isSubmitForRework="isSubmitForRework"
            @showConfirmationModalForRework="showReviewConfirmationModal"
            :hasNonSubmitStatus:="hasNonSubmitStatus"
            message="Are you sure you want to change the status to rework for these entities?"
          ></SubmitAndReworkModal>
        </div>
      </div>
      <div class="toolbar-row">
        <!-- Export Button -->
        <div class="toolbar-column">
          <b-button
            class="action-button export-csv-button"
            size="is-small"
            :disabled="isDownloadDataLoading"
            :loading="isDownloadDataLoading"
            @click="exportCSVAndDownload"
          >
            <b-icon icon="file" size="is-small" class="toolbar-icons"> </b-icon>
            &nbsp; Export CSV
          </b-button>
        </div>

        <div class="toolbar-column">
          <b-button
            class="action-button export-csv-button"
            size="is-medium"
            font-scale="6"
            @click="refreshGridData"
          >
            <b-icon icon="refresh" size="is-small" class="toolbar-icons">
            </b-icon>
          </b-button>
        </div>
      </div>
    </div>
    <Grid
      ref="grid-table"
      :style="{ height: '100%', minHeight: '300px' }"
      :data-items="gridData"
      :resizable="true"
      :reorderable="true"
      :sortable="true"
      :pageable="gridPageable"
      :sort="sort"
      :filter="filter"
      :take="take"
      :skip="skip"
      :total="totalRecords"
      :expand-field="expandField"
      :selectable="true"
      :groupable="true"
      :group="group"
      class="grid-table"
      :loader="loading"
      :selected-field="selectedField"
      :columns="updatedColumns"
      :column-menu="columnMenu"
      :collapsed-groups="collapsedGroups"
      @pagechange="pageChangeHandler"
      @selectionchange="onSelectionChange"
      @headerselectionchange="onHeaderSelectionChange"
      @datastatechange="dataStateChange"
      @groupchange="onGroupChange"
      @expandchange="expandChange"
    >
      <template v-slot:customFilterTemplate="{ props }">
        <custom
          :column="props.column"
          :filterable="props.filterable"
          :filter="props.filter"
          :sortable="props.sortable"
          :sort="props.sort"
          :columns="updatedColumns"
          @sortchange="(e) => props.onSortchange(e)"
          @filterchange="(e, f) => props.onFilterchange(e, f)"
          @closemenu="(e) => props.onClosemenu(e)"
          @contentfocus="(e) => props.onContentfocus(e)"
          @columnssubmit="onColumnsSubmit"
        />
      </template>
      <template v-slot:subscriptionDropDownTemplate="{ props }">
        <td v-if="props.dataItem.sgaId" class="subscription-class">
          <SubscribedDropDown
            :decline-reasons="declinedReasonsList"
            :sga-id="props.dataItem.sgaId"
            :is-subscribed="props.dataItem.isSubscribed"
            :legal-entity-name="props.dataItem.legalEntityName"
            :request-id="props.dataItem.requestId"
            @getDatawithFilter="getDatawithFilter"
          />
        </td>
      </template>
      <template v-slot:viewEntityTemplate="{ props }">
        <td
          v-if="props.dataItem.sgaId"
          class="k-table-td worktype-column-td"
          role="gridcell"
          colspan="1"
        >
          <div class="relationship-button-container">
            {{ props.dataItem.sgaId }}
            <b-tooltip
              position="is-bottom"
              type="is-info"
              v-if="true"
              :label="isPMRole ? 'Edit/View' : 'View'"
              :style="{ position: 'absolute' }"
            >
              <b-icon
                @click.native="
                  navigateToViewEntity(
                    props.dataItem.sgaId,
                    props.dataItem.workType,
                    props.dataItem.assignmentId
                  )
                "
                class="view-icon"
                icon="open-in-new"
                size="is-small"
              ></b-icon>
            </b-tooltip>
          </div>
        </td>
      </template>
      <template v-slot:legalEntityTemplate="{ props }">
        <td
          v-if="props.dataItem.isUpdated"
          class="k-table-td"
          role="gridcell"
          colspan="1"
        >
          <div class="legal-entity-container">
            {{ props.dataItem.legalEntityName }}
            <b-icon icon="flag" size="is-small"></b-icon>
          </div>
        </td>
        <td v-else class="k-table-td" role="gridcell" colspan="1">
          <div class="legal-entity-container">
            {{ props.dataItem.legalEntityName }}
          </div>
        </td>
      </template>
    </Grid>

    <template>
      <AddEntityDialog
        v-if="showAddEntityAddDialog"
        :visible="showAddEntityAddDialog"
        :isFromPMDashboard="true"
        :isPMRole="isPMRole"
        :closeDialog="closeEntityAddDialog"
        @getDatawithFilter="getDatawithFilter"
      />
    </template>
    <template>
      <CorporateActionDialog
        v-if="showCorporateActionDialog"
        :visible="showCorporateActionDialog"
        :isFromPMDashboard="true"
        :isPMRole="isPMRole"
        :closeDialog="closeCorporateActionDialog"
        :entityList="corporateActionEntityList"
        @resetSelection="resetCorporateSelection"
      />
    </template>
    <template>
      <FileUploadDialog
        v-if="fileUploadDialog.isOpen"
        :showDialog="fileUploadDialog.isOpen"
        :closeDialog="closeFileUploadDialog"
      />
    </template>
  </div>
</template>

<script>
import { process } from '@progress/kendo-data-query'
import ExcelJS from 'exceljs'
import Papa from 'papaparse'
import { Grid } from '@progress/kendo-vue-grid'
import { filterIcon } from '@progress/kendo-svg-icons'
import { entityStatus } from '@/constant/data.js'
import { pmDashboardColumns } from './columns'
import SubscribedDropDown from './SubscribedDropDown.vue'
import FileUploadDialog from './FileUploadDialog.vue'
import AddEntityDialog from '@/components/DMP/AddEntityDialog.vue'
import CorporateActionDialog from '@/components/DMP/CorporateActionDialog.vue'
import { downloadCSV } from '../../../util/util'
import Snackbar from '@/components/Snackbar'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import MultiSelectDialog from '@/components/DMP/MultiSelectDialog.vue'
import SingleSelectDialog from '@/components/DMP/SingleSelectDialog.vue'
import { userRoles } from '@/util/permissions.utils'
import ColumnMenu from './ColumnMenu'
import { checkPathPermission } from '@/util/permissions.utils'
import SubmitAndReworkModal from '@/views/DataManagement/PMDashboard/SubmitAndReworkModal.vue'

const DEFAULT_WORK_TYPES = [
  { name: 'Corporate Action', id: 'Corporate Action' },
  { name: 'Data Enrichment', id: 'Data Enrichment' },
  { name: 'New Onboarding', id: 'New Onboarding' },
  { name: 'Periodic Review', id: 'Periodic Review' }
]

export default {
  name: 'PMDashboardGrid',
  components: {
    custom: ColumnMenu,
    Grid,
    AddEntityDialog,
    SubscribedDropDown,
    MultiSelectDialog,
    SingleSelectDialog,
    CorporateActionDialog,
    FileUploadDialog,
    SubmitAndReworkModal
  },
  props: {
    searchValue: {
      type: String
    }
  },
  provide() {
    return {
      kendoIcons: {
        type: 'svg',
        icons: {
          'more-vertical': filterIcon
        }
      }
    }
  },
  data() {
    return {
      searchWord: this.searchValue,
      hasNonSubmitStatus: false,
      columnMenu: false,
      selectedField: 'selected',
      expandField: 'expanded',
      isTeamAllMemberSelect: false,
      isTeamAllSelect: false,
      selectedForRework: [],
      selectedForStaging: [],
      hasNonStagingStatus: false,
      gridPageable: {
        buttonCount: 6,
        info: true,
        type: 'numeric',
        pageSizes: [10, 50, 100, 200],
        previousNext: true
      },
      gridData: [],
      skip: 0,
      take: 10,
      sort: [],
      total: this.totalRecords,
      filter: {
        logic: 'and',
        filters: []
      },
      customFilter: null,
      group: [],
      expandedItems: [],
      collapsedGroups: [],
      selectedIds: new Set(),
      columns: [...pmDashboardColumns],
      dashboardDataItems: [],
      declinedReasonsList: this.declineReasons,
      loading: this.isLoading,
      subscriptionDropdownData: [
        {
          id: 1,
          text: 'Yes',
          value: true
        },
        {
          id: 2,
          text: 'No',
          value: false
        }
      ],
      teamDaa: [],
      teamMemberData: [],
      showAddEntityAddDialog: false,
      userRoles,
      isPMRole: false,
      selectedTeamMemberId: null, // This will hold the selected team member's ID for single user - multiple entities assignment
      showBulkAssignmentTextPopup: false,
      teamFilter: [],
      teamMemberFilterData: [],
      teamFilterData: [],
      isFilterApplied: true,
      showCorporateActionDialog: false,
      corporateActionEntityList: [],
      selectedTeamIds: [],
      selectedAssigneeIds: [],
      isDownloadDataLoading: this.isFileDownloading,
      file: null,
      acceptableFiles: '.csv,.xlsx,.xls',
      fileUploadRequiredHeaders: ['SGA ID', 'Email ID', 'Status'], // The required headers
      maxFileSize: 5 * 1024 * 1024, // 5 MB size limit
      fileUploadDialog: {
        isOpen: false
      }
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('pmDashboard', [
      'pmDashboardData',
      'declinedReasons',
      'teamMemberList',
      'teamList',
      'isLoading',
      'limit',
      'offset',
      'totalRecords',
      'filteredColumnList',
      'isFileDownloading'
    ]),
    ...mapState('user', ['userDetails']),
    areAllSelected() {
      if (!this.dashboardDataItems) {
        return false
      }
      const visibleItems = this.dashboardDataItems // Implement logic to determine currently visible items based on filter
      return visibleItems.every((item) => this.selectedIds.has(item.sgaId))
    },
    updatedColumns() {
      return this.columns.map((column) => {
        // Check if this column has children where updates are needed
        if (column.children && column.children.length > 0) {
          const updatedChildren = column.children.map((child) => {
            // Update the 'selected' column's headerSelectionValue
            if (child.field === 'selected') {
              return {
                ...child,
                headerSelectionValue: this.areAllSelected
              }
            }
            return child
          })

          return {
            ...column,
            children: updatedChildren
          }
        }

        return column
      })
    }
  },
  watch: {
    pmDashboardData: {
      handler() {
        this.combineFilterData()
      },
      immediate: true
    },
    declinedReasons(newVal) {
      this.declinedReasonsList = newVal
    },
    isLoading(newVal) {
      this.loading = newVal
    },
    searchValue(newVal) {
      this.filterGridData(newVal)
    },
    limit(newVal) {
      this.take = newVal
    },
    offset(newVal) {
      this.skip = newVal
    },
    totalRecords(newVal) {
      this.total = newVal
    },

    filteredColumnList() {
      this.columns = this.getColumnWithFilteredApplied()
    },

    isFileDownloading(newVal) {
      this.isDownloadDataLoading = newVal
    }
  },
  async mounted() {
    try {
      this.isPMRole = this.userRoles.PM_MANAGER === this.userDetails.roleName
      this.clearFilterHighlights()
      await this.getDeclinedReasons()
      await this.getTeamMemberList(this.userDetails.userId)
      await this.getTeamList(this.userDetails.userId)
      await this.resetPaginationData()
      this.resetSelection()
      await this.getpmDashboardData()
      if (this.searchWord) {
        this.filterGridData(this.searchWord)
      }
    } catch (error) {
      console.log('ERROR IN MOUNTED IS ', error)
    }
  },
  methods: {
    ...mapActions('pmDashboard', [
      'getpmDashboardData',
      'downloadPmDashboardData',
      'getDeclinedReasons',
      'updateSubscription',
      'getTeamMemberList',
      'getTeamList',
      'sendEntitiesBackToPool',
      'assignEntities',
      'triggerBulkAssignment',
      'setLimitAndOffset',
      'resetPaginationData',
      'updateCustomFilter',
      'updateTeamsFilter',
      'updateAssigneesFilter',
      'updateSearchQuery',
      'updateWorkTypeFilter',
      'setDashboardLoader',
      'refreshMaterializedView',
      'uploadFile',
      'submitPopupStatus',
      'reworkPopupStatus'
    ]),
    ...mapActions('dmp', ['updateEntitiesStatus']),
    ...mapActions('assignedEntities', ['getEntityDetailOverView']),
    ...mapActions('review', ['updateEntityToSubmit', 'updateEntityToRework']),

    processGridData(data, skipPagination = false) {
      return process(data, {
        take: skipPagination ? data.length : this.take,
        skip: skipPagination ? 0 : 0, // hardcoding skip to 0 as data is fetched through server side pagination
        sort: this.sort,
        group: this.group,
        filter: this.filter
      })
    },
    async showConfirmationModalForSubmit() {
      this.selectedForStaging = this.getSelectedIds()
      this.hasNonStagingStatus = this.selectedForStaging.some(
        (item) => item.status !== entityStatus.STAGING
      )

      if (this.selectedForStaging && this.selectedForStaging.length > 0) {
        if (this.hasNonStagingStatus) {
          Snackbar({
            message: 'Please select only Sga id whose status is staging.',
            type: 'is-danger'
          })
          this.submitPopupStatus(false)
        } else {
          this.submitPopupStatus(true)
        }
      } else {
        Snackbar({
          message: 'Select at least one item to proceed.',
          type: 'is-danger'
        })
        this.submitPopupStatus(false)
      }
    },

    async showReviewConfirmationModal() {
      this.selectedForRework = this.getSelectedIds()
      this.hasNonSubmitStatus = this.selectedForRework.some(
        (item) =>
          item.status.toLowerCase() !== entityStatus.SUBMIT.toLowerCase()
      )

      if (this.selectedForRework && this.selectedForRework.length > 0) {
        if (this.hasNonSubmitStatus) {
          Snackbar({
            message:
              'Please select only Sga id whose status is Submitted / Ready for Delivery.',
            type: 'is-danger'
          })
          this.reworkPopupStatus(false)
        } else {
          this.reworkPopupStatus(true)
        }
      } else {
        Snackbar({
          message: 'Select at least one item to proceed.',
          type: 'is-danger'
        })
        this.reworkPopupStatus(false)
      }
    },
    async isSubmitForRework() {
      const selectedAssigneeIds = []
      this.selectedForRework.forEach((ele) => {
        selectedAssigneeIds.push(Number(ele.assignmentId))
      })

      const entityStatusData = {
        assignmentIds: selectedAssigneeIds,
        targetEntityStatus: 'Rework'
      }

      try {
        this.loading = true
        let isSuccess = false

        isSuccess = await this.updateEntityToSubmit(entityStatusData)

        if (isSuccess) {
          this.selectedIds.clear() // Reset the selection after operation is successful
          this.setLimitAndOffset({ limit: this.take, offset: 0 })
          await this.refreshDashboardData()

          this.snackbarPayload.message = 'Details submitted successfully.'
        } else {
          throw new Error()
        }
      } catch (error) {
        this.snackbarPayload.message = 'Failed to submit the Details.'
      } finally {
        this.loading = false
      }
      Snackbar(this.snackbarPayload)
    },

    async isSubmitForDelivery() {
      const selectedAssigneeIds = []
      const currentStatus = []

      this.selectedForStaging.forEach((ele) => {
        selectedAssigneeIds.push(Number(ele.assignmentId))
        currentStatus.push(ele.status)
      })

      const entityStatusData = {
        assignmentIds: selectedAssigneeIds,
        targetEntityStatus: 'Submitted / Ready for Delivery'
      }

      try {
        let isSuccess = false
        this.loading = true

        isSuccess = await this.updateEntityToSubmit(entityStatusData)

        if (isSuccess) {
          this.selectedIds.clear() // Reset the selection after operation is successful
          this.setLimitAndOffset({ limit: this.take, offset: 0 })
          await this.refreshDashboardData()
          this.snackbarPayload.message = 'Details submitted successfully.'
        } else {
          throw new Error()
        }
      } catch (error) {
        this.snackbarPayload.message = 'Failed to submit the Details.'
      } finally {
        this.loading = false
        this.submitPopupStatus(false)
      }
      Snackbar(this.snackbarPayload)
    },

    resetSelection() {
      this.selectedIds.clear()
    },

    async refreshGridData() {
      await this.refreshMaterializedView()
      await this.getpmDashboardData()
    },

    async getDatawithFilter(data) {
      this.isFilterApplied = data
      await this.getpmDashboardData()
    },
    async navigateToViewEntity(sgaId, workType, assignmentId) {
      // View Entity Redirection
      const workTypeOrder = [
        'New Onboarding',
        'Data Enrichment',
        'Periodic Review',
        'Corporate Action'
      ]
      let redirectionWorktype = null
      for (let index = 0; index < workTypeOrder.length; index++) {
        if (workType && workType.includes(workTypeOrder[index])) {
          redirectionWorktype = workTypeOrder[index]
          break
        }
      }
      redirectionWorktype = redirectionWorktype || 'Data Enrichment'
      await this.updateEntitiesStatus({
        entityStatus: false,
        entityDetails: { sgaId, workType: redirectionWorktype, assignmentId }
      })
      this.clearPayload()
      if (this.isPMRole) {
        await this.$router.push({ name: 'entities' })
      } else {
        this.$router.push({
          name: 'viewEntity'
        })
      }
    },
    pageChangeHandler: async function (event) {
      this.skip = event.page.skip // offset
      this.take = event.page.take // limit
      this.setLimitAndOffset({ limit: this.take, offset: this.skip })
      this.resetSelection()
      await this.getpmDashboardData()
    },
    clearPayload() {
      this.resetPaginationData()
      this.updateCustomFilter(null)
      this.updateTeamsFilter([])
      this.updateAssigneesFilter([])
      this.updateSearchQuery(null)
    },
    async globalSearchValue(searchContent) {
      this.searchQuery = searchContent
      this.updateSearchQuery(this.searchQuery)
      this.setLimitAndOffset({ limit: this.take, offset: 0 })
      await this.getpmDashboardData()
    },
    async workTypeFilter(workType) {
      const finalWorkType = workType
        ? workType.map((typeObj) => typeObj.id)
        : []
      this.updateWorkTypeFilter(finalWorkType)
      this.setLimitAndOffset({ limit: this.take, offset: 0 })
      await this.getpmDashboardData()
    },
    updateFilter(newFilters, filterType) {
      // Initialize this.filter if it's not already defined
      if (!this.filter) {
        this.filter = {
          logic: 'and',
          filters: []
        }
      }

      // Remove existing filters of the same type to avoid duplicates
      this.filter.filters = this.filter.filters.filter(
        (f) => f.filterType !== filterType
      )
      // If the new filter is a group (has 'filters' and 'logic'), handle it accordingly
      if (
        newFilters.length === 1 &&
        newFilters[0].filters &&
        newFilters[0].logic
      ) {
        // Add filter type to the group for identification, if needed
        newFilters[0].filterType = filterType
        // Append the new filter group directly
        this.filter.filters.push(newFilters[0])
      } else {
        // For individual filters, mark them with their type and append
        newFilters.forEach((f) => {
          f.filterType = filterType // Mark filters with their type for easy identification
          this.filter.filters.push(f)
        })
      }
      this.skip = 0
      // Trigger the grid update with the new filters
      this.combineFilterData()
    },
    getColumnByFieldName(columns, fieldName) {
      let found = false
      // Direct match at the current level
      for (const column of columns) {
        if (column.field === fieldName) {
          return column
        }

        // Recursively search in children
        if (column.children && column.children.length > 0) {
          found = this.getColumnByFieldName(column.children, fieldName)
          if (found) {
            return found // Found column in children
          }
        }
      }
      // No matching column found
      return false
    },
    clearFilterHighlights(columns = this.columns) {
      for (const column of columns) {
        // Clear highlighting
        if (column.headerClassName) {
          column.headerClassName = ''
        }
        // Recurse if children exist
        if (column.children && column.children.length > 0) {
          this.clearFilterHighlights(column.children)
        }
      }
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState
    },
    constructFilter(inputValue, filterFields) {
      const filters = []
      const isValueArray = Array.isArray(inputValue)
      const isFieldArray = Array.isArray(filterFields)

      const addFilters = (field, value) => {
        filters.push({
          field: field,
          operator: 'contains',
          value: value,
          ignoreCase: true
        })
      }

      if (isValueArray) {
        inputValue.forEach((value) => {
          if (filterFields === 'workType') {
            value = value.id
          }
          if (isFieldArray) {
            filterFields.forEach((field) => addFilters(field, value))
          } else {
            addFilters(filterFields, value)
          }
        })
      } else {
        if (isFieldArray) {
          filterFields.forEach((field) => addFilters(field, inputValue))
        } else {
          addFilters(filterFields, inputValue)
        }
      }

      return {
        logic: 'or',
        filters: filters
      }
    },
    dataStateChange(event) {
      if (!event.event?.field) {
        // Skipped the filter process on title click
        return
      }
      this.filter = event.data.filter || this.filter
      this.take = event.data.take
      this.skip = event.data.skip
      this.sort = event.data.sort
      this.group = event.data.group

      this.setLimitAndOffset({ limit: this.take, offset: this.skip })
      // Check if filters are cleared and reset grid data accordingly
      if (!event.data.filter || event.data.filter.filters.length === 0) {
        // If filter is cleared, reset any custom filtering you have
        this.filter = null
        this.updateCustomFilter(this.filter)
        this.getpmDashboardData()
        // Reset to initial data or fetch again as needed
        this.updateGridData(this.dashboardDataItems)
      } else {
        // Handle other data state changes like sorting, pagination as before
        this.updateCustomFilter(this.filter.filters)
        this.getpmDashboardData()
      }
    },
    updateGridData(data) {
      const filteredAndUpdatedData = this.processAndFilterData(data)
      const processedData = this.processGridData(filteredAndUpdatedData)
      const updatedProcessedDataWithSelection = processedData.data.map(
        (item) => {
          return { ...item, selected: this.selectedIds.has(item.sgaId) }
        }
      )
      this.gridData = updatedProcessedDataWithSelection
      // this.total = processedData.total // not required as of now
    },
    processAndFilterData(data) {
      return data.reduce((acc, item) => {
        // Check if item matches the custom filter
        const matchesFilter = this.customFilter
          ? this.customFilter.filters.some((filter) => {
              const itemFieldValue = item[filter.field]
              if (Array.isArray(itemFieldValue)) {
                return itemFieldValue.some((value) =>
                  value
                    .toString()
                    .toLowerCase()
                    .includes(filter.value.toString().toLowerCase())
                )
              } else {
                return itemFieldValue
                  .toString()
                  .toLowerCase()
                  .includes(filter.value)
              }
            })
          : true // No filter applied

        if (matchesFilter) {
          acc.push({
            ...item,
            selected: this.selectedIds.has(item.sgaId) // Update selection state
          })
        }

        return acc
      }, [])
    },
    async combineFilterData(data) {
      if (
        this.teamFilterData.length !== 0 &&
        this.teamMemberFilterData.length !== 0
      ) {
        // Handle the error, you can throw an error or return a specific value
        // Convert arrays to sets to find common elements

        const set1 = new Set(this.teamFilterData)
        const set2 = new Set(this.teamMemberFilterData)

        // Use the filter method to get elements present in both sets
        const intersectionArray = [...set1].filter((element) =>
          set2.has(element)
        )
        const updatedDashboardDataItems = this.pmDashboardData.filter((item) =>
          intersectionArray.includes(item.assignedUserId)
        )
        this.dashboardDataItems = updatedDashboardDataItems
        this.updateGridData(updatedDashboardDataItems)
      } else if (
        this.teamFilterData.length === 0 &&
        this.teamMemberFilterData.length === 0
      ) {
        this.dashboardDataItems = this.pmDashboardData
        this.updateGridData(this.pmDashboardData)
      } else if (
        this.teamFilterData.length !== 0 &&
        this.teamMemberFilterData.length === 0
      ) {
        const updatedDashboardDataItems = this.pmDashboardData.filter((item) =>
          this.teamFilterData.includes(item.assignedUserId)
        )

        this.dashboardDataItems = updatedDashboardDataItems
        this.updateGridData(updatedDashboardDataItems)
      } else if (
        this.teamFilterData.length === 0 &&
        this.teamMemberFilterData.length !== 0
      ) {
        const updatedDashboardDataItems = this.pmDashboardData.filter((item) =>
          this.teamMemberFilterData.includes(item.assignedUserId)
        )
        this.dashboardDataItems = updatedDashboardDataItems
        this.updateGridData(updatedDashboardDataItems)
      }
    },

    async filterWithTeam(data) {
      if (data && data.length > 0) {
        const numericArray = data.map((team) => +team.id)
        this.selectedTeamIds = numericArray
      } else {
        this.selectedTeamIds = []
      }
      this.updateTeamsFilter(this.selectedTeamIds)
      this.setLimitAndOffset({ limit: this.take, offset: 0 })
      await this.getpmDashboardData()
    },

    async filterDashboardData(data) {
      if (data && data.length > 0) {
        const mapData = data.map((team) => Number(team.userId))
        this.selectedAssigneeIds = mapData
      } else {
        this.selectedAssigneeIds = []
      }
      this.updateAssigneesFilter(this.selectedAssigneeIds)
      this.setLimitAndOffset({ limit: this.take, offset: 0 })
      await this.getpmDashboardData()
    },
    filterGridData(value) {
      const searchValue = value.trim()
      // Create a filter group with "OR" logic for the search fields
      const searchFilterGroup = {
        logic: 'or', // Apply "OR" logic between the search filters
        filters: [
          {
            field: 'sgaId',
            operator: 'contains',
            value: searchValue,
            ignoreCase: true
          },
          {
            field: 'requestId',
            operator: 'contains',
            value: searchValue,
            ignoreCase: true
          },
          {
            field: 'legalEntityName',
            operator: 'contains',
            value: searchValue,
            ignoreCase: true
          }
        ]
      }
      // Update the main filter object to include this filter group
      this.updateFilter([searchFilterGroup], 'search')
    },
    onHeaderSelectionChange(event) {
      const checked = event.event.target.checked
      const tempData = this.dashboardDataItems.map((item) => {
        return { ...item, selected: checked }
      })

      const tempGridData = this.gridData.map((item) => {
        return { ...item, selected: checked }
      })
      if (checked) {
        // If checked is true, add all sgaId to the selectedIds set
        tempData.forEach((item) => this.selectedIds.add(item.sgaId))
      } else {
        // If checked is false, clear the selectedIds set
        this.selectedIds.clear()
      }
      this.selectedIds = new Set(this.selectedIds)
      this.gridData = tempGridData
      this.dashboardDataItems = tempData
      this.updateGridData(this.dashboardDataItems)
    },
    expandChange: function (event) {
      this.collapsedGroups = event.collapsedGroups
      if (event.dataItem) {
        event.dataItem[event.target.$props.expandField] = event.value
      }
    },
    formatCamelCaseToTitle(camelCaseString) {
      // Insert a space before each uppercase letter, except for the first character
      const spacedString = camelCaseString.replace(/([A-Z])/g, ' $1')

      // Capitalize the first letter of the resulting string
      const capitalizedString =
        spacedString.charAt(0).toUpperCase() + spacedString.slice(1)

      return capitalizedString
    },
    onGroupChange: function (event) {
      // Determine if we're adding or removing a group by comparing lengths
      const isAddingGroup = event.group.length > this.group.length

      if (isAddingGroup) {
        // Extract the field name of the new grouping request
        const newGroupField = event.group[event.group.length - 1]?.field

        // Check if this field is already used in existing groups
        const isFieldAlreadyGrouped = this.group.some(
          (group) => group.field === newGroupField
        )

        if (isFieldAlreadyGrouped) {
          const formattedFieldName = this.formatCamelCaseToTitle(newGroupField) // Format the field name
          // If the field is already grouped, show a warning and don't update the groupings
          Snackbar({
            message: `Column '${formattedFieldName}' is already grouped.`,
            type: 'is-warning'
          })
          return // Stop further processing
        }
      }

      // Update groupings for both adding a new group and removing an existing one
      this.group = event.group
      this.combineFilterData()
    },

    onSelectionChange(event) {
      const sgaId = event.dataItem.sgaId
      const isSelected = !event.dataItem.selected

      // Update selection in selectedIds
      if (isSelected) {
        this.selectedIds.add(sgaId)
      } else {
        this.selectedIds.delete(sgaId)
      }
      this.dashboardDataItems.forEach((item) => {
        if (item.sgaId === sgaId) {
          item.selected = isSelected
        }
      })
      // Function to recursively update the selection state
      const updateSelection = (items) => {
        items.forEach((item) => {
          if (item.items) {
            // If the item has nested items, recurse
            updateSelection(item.items)
          } else if (item.sgaId === sgaId) {
            // Base case, update the item
            // Using Vue.set for reactivity in nested objects
            this.$set(item, 'selected', isSelected)
          }
        })
      }

      // Update the grid data's selection state deeply
      updateSelection(this.gridData)
      this.selectedIds = new Set(this.selectedIds)
      this.dashboardDataItems = [...this.dashboardDataItems]
    },
    closeEntityAddDialog() {
      this.showAddEntityAddDialog = false
    },
    handleShowEntityDialog() {
      this.showAddEntityAddDialog = true
    },
    closeCorporateActionDialog() {
      this.showCorporateActionDialog = false
    },
    getSelectedIds() {
      const filteredAndUpdatedData = this.processAndFilterData(
        this.dashboardDataItems
      )
      const processedData = this.processGridData(filteredAndUpdatedData, true) // Pass true to skip pagination for full processing
      let finalData = processedData.data
      if (this.group.length > 0) {
        finalData = this.flattenGroupedData(processedData.data) // Flatten the grouped data
      }

      const selectedItems = finalData.filter((item) => item.selected)
      return selectedItems
    },
    handleCorporateActionDialog() {
      const selectedItems = this.getSelectedIds()

      if (selectedItems.length === 0) {
        Snackbar({
          message: 'Select at least one item to proceed.',
          type: 'is-danger'
        })
        return
      }
      this.corporateActionEntityList = selectedItems
      this.showCorporateActionDialog = true
    },
    async handleBackToPool() {
      const selectedItems = this.getSelectedIds()

      if (selectedItems.length === 0) {
        Snackbar({
          message: 'Select at least one item to proceed.',
          type: 'is-danger'
        })
        return
      }

      const unassignedItems = selectedItems.filter((item) => !item.assignedTo)
      if (unassignedItems.length > 0) {
        Snackbar({
          message: `${unassignedItems.length} unassigned items cannot be sent back to pool.`,
          type: 'is-danger'
        })
        return
      }

      const selectedPoolIds = selectedItems.map((item) => item.poolId)
      const payload = {
        poolIds: selectedPoolIds
      }

      const responseStatus = await this.sendEntitiesBackToPool(payload)
      if (responseStatus === 200) {
        this.selectedIds.clear() // Reset the selection after operation is successful
        this.setLimitAndOffset({ limit: this.take, offset: 0 })
        await this.refreshDashboardData()
      } else {
        Snackbar({
          message: 'Error in sending entities back to pool.',
          type: 'is-danger'
        })
      }
    },
    async handleAssignEntities(userId) {
      const selectedItems = this.getSelectedIds()

      if (selectedItems.length === 0) {
        Snackbar({
          message: 'Select at least one item to proceed.',
          type: 'is-danger'
        })
        return
      }
      const selectedPoolIds = selectedItems.map((item) => item.poolId)
      const payload = {
        poolIds: selectedPoolIds,
        userId: userId
      }
      const assignedUserIds = selectedItems.map((item) => item.assignedUserId)
      const hasDifferentId = assignedUserIds.some((id) => String(id) !== userId)
      if (hasDifferentId) {
        const previousTotalRecords = this.total
        const responseStatus = await this.assignEntities(payload)
        if (responseStatus === 200) {
          this.selectedIds = new Set() // Reset the selection after operation is successful
          await this.refreshDashboardData() // TODO: need to do something here
          const newTotalRecords = this.total
          if (newTotalRecords === 0 && previousTotalRecords > newTotalRecords) {
            this.setLimitAndOffset({ limit: this.take, offset: 0 })
            await this.getpmDashboardData()
          }
        } else {
          Snackbar({
            message: 'Error in assigning entities.',
            type: 'is-danger'
          })
        }
      } else {
        Snackbar({
          message: 'Please choose another assignee.',
          type: 'is-info'
        })
      }
    },
    async refreshDashboardData() {
      this.isFilterApplied = false
      this.setDashboardLoader(true)
      await this.getEntityDetailOverView()
      await this.getpmDashboardData()
    },
    flattenGroupedData(groupedData) {
      const flatData = []

      const processGroupItems = (items) => {
        items.forEach((item) => {
          // Check if the item is a group with further nested items
          if (
            item.type === 'group' ||
            (item.items && Array.isArray(item.items))
          ) {
            processGroupItems(item.items) // Recursively process nested items
          } else {
            // This is an actual data item, add it to the flat data array
            flatData.push(item)
          }
        })
      }

      // Start processing with the top-level group items
      processGroupItems(groupedData)

      return flatData
    },

    async exportCSVAndDownload() {
      const filteredAndUpdatedData = this.processAndFilterData(
        this.dashboardDataItems
      )

      const processedData = this.processGridData(filteredAndUpdatedData, true)
      let dataForExport = processedData.data
      if (this.group.length > 0) {
        // Flatten the grouped data
        dataForExport = this.flattenGroupedData(dataForExport)
      }

      if (!dataForExport.length) {
        Snackbar({ message: 'No Data to export.', type: 'is-danger' })
        return
      }

      const userCheckedData = dataForExport.filter(
        (item) => item.selected === true
      )
      // In case if any item is selected/checked by user, thenn download only the checked data
      if (userCheckedData.length) {
        dataForExport = userCheckedData
      } else {
        Snackbar({
          message:
            'Export in progress. This may take a few minutes. The file will be downloaded in the background.',
          type: 'is-info'
        })
        await this.downloadPmDashboardData()
        return
      }

      // Extracting column headers and fields, excluding 'selected' field
      const parentHeaders = []
      const columnHeaders = []
      const columnFields = []
      this.columns.forEach((column) => {
        parentHeaders.push(column.title)
        column.children.forEach((child, idx) => {
          if (child.field !== 'selected') {
            columnHeaders.push(child.title || child.field)
            columnFields.push(child.field)
            if (idx !== column.children.length - 1) {
              parentHeaders.push('')
            }
          }
        })
      })

      const headers = parentHeaders.join(';') + '\n' + columnHeaders.join(';')

      // Format data for CSV
      const csvData = dataForExport.map((item) =>
        columnFields
          .map((field) => {
            // Check if the field in the item is not null or undefined
            if (![null, undefined].includes(item[field])) {
              // Check if the field's value is a number
              if (typeof item[field] === 'number') {
                // Format the number as a plain integer with no decimal places
                return item[field].toLocaleString('en-US', {
                  maximumFractionDigits: 0
                })
              } else {
                // Convert the field's value to a string
                return item[field].toString()
              }
            }
            // If the field is null or undefined, return an empty string
            return ''
          })
          .join(';')
      )

      const fileName = `pm-dashboard-data_${moment().format(
        'DD-MM-YYYY_HH:mm'
      )}.csv`
      const csvContent = `sep=;\n${headers}\n${csvData.join('\n')}`

      downloadCSV(csvContent, fileName)
    },
    async handleBulkAssignment() {
      const responseStatus = await this.triggerBulkAssignment()
      if (responseStatus === 200) {
        Snackbar({
          message: 'Bulk assignment job triggered successfully.',
          type: 'is-success'
        })
      } else {
        const errorMessage =
          responseStatus < 0
            ? 'Missing API configuration. Please contact admin.'
            : 'Something went wrong while triggering assignment job.'
        Snackbar({
          message: errorMessage,
          type: 'is-danger'
        })
      }
    },
    bulkAssignmentTextPopup() {
      this.showBulkAssignmentTextPopup = true
    },
    closeBulkAssignmentTextPopup() {
      this.showBulkAssignmentTextPopup = false
    },

    async resetCorporateSelection(isClearSelection) {
      if (isClearSelection) {
        this.selectedIds.clear()
        await this.refreshDashboardData()
      }
    },

    getColumnWithFilteredApplied() {
      const allColumns = this.columns.map((groupCol) => {
        const children = groupCol.children.map((col) => {
          let filterIndicator = ''
          const index = this.filteredColumnList.indexOf(col.field)
          if (index > -1) {
            filterIndicator = 'filter-indicator'
          }
          return {
            ...col,
            headerClassName: filterIndicator
          }
        })
        return {
          ...groupCol,
          children: children
        }
      })
      return allColumns
    },
    handleFileUploadDialog() {
      this.fileUploadDialog.isOpen = true
    },
    closeFileUploadDialog() {
      this.fileUploadDialog.isOpen = false
    }
  }
}
</script>

<style lang="scss">
.pop-content-1 {
  height: 500px;
  background: blue;
  width: 300px;
}

.mdi-flag::before {
  content: '\F023B';
  font-size: 25px;
}

.legal-entity-container {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  align-items: center;
  flex-direction: row;
  padding-top: 4px;
  padding-bottom: 4px;
}

.pm-dashboard-container {
  margin: 27px 0px;
  height: 100%;
  z-index: 10;
  position: relative;

  .multiselet-dialog {
    position: absolute;
    height: 200px;
    width: 200px;
    bottom: 0px;
    // top: 400px;
    left: 400px;
    z-index: 99;
    background: #00218a;
  }
  .grid-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 26px;
    border: none;
    background-color: var(--background-color);
  }

  .toolbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding-bottom: 1rem;

    .toolbar-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      gap: 1rem;
    }

    .action-button {
      font-style: normal;
      font-variant: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      font-family: 'Quicksand', sans-serif;
      border-radius: 4px;
    }

    .add-entity-button {
      color: #00218a;
      border: 1px solid #00218a;
      &:hover {
        background: #00218a 0% 0% no-repeat padding-box;
        border: 1px solid #00218a;
        color: #fff;
      }
    }

    .toolbar-icons {
      width: 17px;
      height: 17px;
    }

    .toolbar-share-icon {
      transform: scaleX(-1);
    }

    .toolbar-icons-set-assignees {
      color: #00218a;
    }

    .assign-button {
      background: #535eeb;
      border: 1px solid #535eeb;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    .back-to-pool-button {
      color: #535eeb;
      border: 1px solid #535eeb;
      &:hover {
        background: #00218a;
        border: 1px solid #00218a;
        color: #fff;
      }
    }

    .export-csv-button {
      border: 1px solid #444444;
      color: #444444;
      &:hover {
        background: #444444;
        border: 1px solid #444444;
        color: #fff;
      }
    }
    .set-assignees-button {
      border: none;
      background-color: var(--background-color);
      font-style: normal;
      font-variant: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      font-family: 'Quicksand', sans-serif;
      border: 1px solid transparent;
      border-radius: 4px;
      &:hover {
        /* background: #00218a 0% 0% no-repeat padding-box; */
        background-color: var(--background-color);
        border: 1px solid #00218a;
        /* color: #fff; */
        color: #00218a;
      }
    }
    .bulk-assignment-button {
      border: none;
      background-color: var(--background-color);
      font-style: normal;
      font-variant: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      font-family: 'Quicksand', sans-serif;
      border: 1px solid transparent;
      border-radius: 4px;
      &:hover {
        background-color: var(--background-color);
        border: 1px solid #00218a;
        color: #00218a;
      }
    }

    .text-popup {
      display: flex;
      align-items: center;
      position: absolute;
      top: 37px;
      p {
        font-family: Quicksand;
        font-size: 13px;
        padding: 2px;
        letter-spacing: 0.16px;
        color: #00218a;
        opacity: 1;
        margin-left: -30px;
      }
    }
  }
  .grid-table {
    .k-grid-header {
      padding: 0px;
      .k-table-row:first-child {
        .k-table-th:first-child {
          background: #c4c8f8;
        }
        .k-table-th {
          background: #8d95f5;
          text-align: left;
          font-style: normal;
          font-variant: normal;
          font-weight: medium;
          font-size: 20px;
          line-height: 26px;
          font-family: Quicksand;

          letter-spacing: 0.24px;
          color: #ffffff;
          text-align: center;

          .k-cell-inner {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            overflow: hidden;
          }
        }
      }
      .k-table-row {
        .k-table-th {
          background: #dfdfdf;
          font-size: 16px;
          color: #000000;
          line-height: 19px;
        }
      }
      .k-header > div > a {
        top: 4px;
      }
      .k-header.active > div > a {
        color: #fff;
        background-color: #ff6358;
        top: 4px;
      }
    }

    .k-grid-aria-root {
      .k-grid-container {
        .k-grid-content {
          .k-grid-table-wrap {
            .k-table {
              .k-table-tbody {
                .k-table-alt-row {
                  background-color: none;
                }
                .k-selected {
                  background-color: none;
                }
                .k-table-row {
                  border: 1px solid black;
                  .k-table-td {
                    font-style: normal;
                    font-variant: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: Quicksand;

                    letter-spacing: 0.16px;
                    color: #000000;
                  }
                }
              }
            }
          }
        }
      }
    }

    .k-grid-pager {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      .k-pager-info {
        display: flex;
        text-align: right;
        justify-content: flex-start;
      }
    }
  }

  .view-icon {
    cursor: pointer;
    color: #535eeb;
  }
}
.subscription-class {
  .k-picker-outline {
    border: none;
    &:focus-within {
      box-shadow: none;
    }
    .k-input-inner {
      color: #000000;
    }
    .k-input-button {
      color: #444444;
    }
    &:hover {
      background-color: #fff;
    }
  }
}

.k-animation-container {
  .k-list-container {
    .k-list {
      .k-list-content {
        .k-list-ul {
          .k-list-item.k-selected {
            background-color: #00218a;
          }
        }
      }
    }
  }
}

.filter-indicator {
  .k-grid-column-menu {
    background-color: #ff6358;
  }
}

::v-deep .file {
  background-color: yellow;
}

.file-action-button {
  .file-label {
    .file-cta {
      max-height: 35px;
      height: 100%;
    }
  }
  .file-name {
    max-height: 35px;
  }
  padding-bottom: 1rem;
  max-width: 100%;
  width: 100%;
}

@media (min-width: 1450px) and (max-width: 1700px) {
}

@media (min-width: 1700px) {
  .pm-dashboard-container {
    .grid-table {
      .k-toolbar {
        .action-button {
          font-size: 16px;
        }

        .toolbar-icons {
          width: 20px;
          height: 20px;
        }
      }

      .k-grid-header {
        padding: 0px !important;
        .k-table-row:first-child {
          .k-table-th {
            font-size: 24px;
            line-height: 30px;
          }
        }
        .k-table-row {
          .k-table-th {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }

      .k-grid-aria-root {
        .k-grid-container {
          .k-grid-content {
            .k-grid-table-wrap {
              .k-table {
                .k-table-tbody {
                  .k-table-row {
                    .k-table-td {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .k-grid-pager {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .k-pager-info {
          font-size: 16px;
        }
        .k-pager-sizes {
          font-size: 16px;
          .k-dropdownlist {
            font-size: 16px;
          }
        }
        .k-pager-numbers-wrap {
          font-size: 16px;
          .k-pager-numbers {
            button {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .subscription-class {
    .k-picker-outline {
      .k-input-inner {
        font-size: 16px;
      }
    }
  }
}
</style>
