<template>
  <div class="bvd-modal">
    <span>
      <button class="button add-button" @click="toggleDialog">
        {{ title }}
      </button>
    </span>
    <k-dialog
      v-if="title === 'Rework' ? shouldRewokPopupOpen : shouldOpenPopup"
      :title="title"
      @close="closeDialog"
    >
      <div :style="{ marginLeft: '25px', width: '40vw' }">
        <p>
          {{ message }}
        </p>
        <div
          v-for="(values, index) of selectedData"
          :key="index"
          class="custom-dialog-box"
        >
          <p class="sga-text">{{ values.sgaId }}</p>
          <p class="legal-text">{{ values.legalEntityName }}</p>
        </div>
        <dialog-actions-bar>
          <button class="button" @click="closeDialog">No</button>
          <button
            class="button"
            :theme-color="'primary'"
            @click="submitDialog()"
          >
            Yes
          </button>
        </dialog-actions-bar>
      </div>
    </k-dialog>
  </div>
</template>
<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import Snackbar from '@/components/Snackbar'
import { mapActions, mapState } from 'vuex'

import { Button } from '@progress/kendo-vue-buttons'

export default {
  name: 'BVDSubmitForDeliveryPopup',
  components: {
    'k-dialog': Dialog,
    'dialog-actions-bar': DialogActionsBar,
    kbutton: Button
  },
  props: {
    title: {
      type: String
    },
    selectedData: {
      type: Array
    },
    isSubmitForDelivery: {
      type: Function
    },
    isSubmitForRework: {
      type: Function
    },
    message: {
      type: String
    },
    showConfirmationModalForSubmit: {
      type: Function
    },
    showConfirmationModalForRework: {
      type: Function
    }
  },
  data: function () {
    return {
      visibleDialog: false
    }
  },
  computed: {
    ...mapState('pmDashboard', ['shouldOpenPopup', 'shouldRewokPopupOpen']),
    valuesArray() {
      return Object.values(this.selectedKey).join(', ')
    }
  },

  methods: {
    ...mapActions('pmDashboard', ['submitPopupStatus', 'reworkPopupStatus']),
    async toggleDialog() {
      if (this.title === 'Rework') {
        this.$emit('showConfirmationModalForRework')
      } else {
        this.$emit('showConfirmationModalForSubmit')
      }
    },
    closeDialog() {
      if (this.title === 'Rework') {
        this.reworkPopupStatus(false)
      } else {
        this.submitPopupStatus(false)
      }
    },
    submitDialog() {
      if (this.title === 'Rework') {
        this.$emit('isSubmitForRework')
        this.reworkPopupStatus(false)
      } else {
        this.$emit('isSubmitForDelivery')
        this.submitPopupStatus(false)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-dialog-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  .sga-text {
    margin-top: 10px;
    width: 30%;
  }
  .legal-text {
    width: 65%;
    margin-top: 10px;
  }
}

.button {
  background: #535eeb 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-left: 0.5rem;
  opacity: 1;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
}
.add-button {
  color: #00218a;
  border: 1px solid #00218a;
  background: white;
  border-radius: 4px;
  &:hover {
    background: #00218a 0% 0% no-repeat padding-box;
    color: white;
  }
}
</style>
