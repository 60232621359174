<template>
  <div class="corporate-action-row">
    <td>
      <div class="common-height">
        {{ entity.sgaId }}
      </div>
    </td>
    <td>
      <div class="common-height">{{ entity.requestId }}</div>
    </td>
    <td>
      <div class="common-height">{{ entity.legalEntityName }}</div>
    </td>
    <td>
      <div class="common-height">
        {{ entity.countryDOMName }}
      </div>
    </td>
    <!-- country of registry -->
    <!-- <td>
      <div class="common-height">
        {{ entity.countryDOMName }}
      </div>
    </td> -->
    <td>
      <div class="common-height center-column-data">
        <b-radio
          v-model="markedAsMasterEntity"
          name="name"
          :native-value="entity.sgaId"
          @input="onRadioInput"
          :disabled="!entity.isSubscribed"
        >
        </b-radio>
      </div>
    </td>
    <td>
      <div class="common-height center-column-data">
        <b-checkbox
          :value="isUnSubscribed"
          @input="onCheckboxInput"
          :native-value="entity.sgaId"
          :disabled="masterEntity === entity.sgaId || !entity.isSubscribed"
        >
        </b-checkbox>
      </div>
    </td>
  </div>
</template>

<script>
import { isNull } from 'lodash'

export default {
  name: 'CorporateActionItemRow',
  components: {},
  props: ['entity', 'masterEntity'],
  data() {
    return { markedAsMasterEntity: null, isUnSubscribed: false }
  },
  watch: {
    isUnSubscribed() {
      this.$emit('unSubscribe', {
        sgaId: this.entity.sgaId,
        isUnSubscribed: this.isUnSubscribed
      })
    },
    masterEntity() {
      // this handles when masterEntity is gets changed
      if (
        !isNull(this.markedAsMasterEntity) &&
        this.masterEntity === this.markedAsMasterEntity
      ) {
        this.isUnSubscribed = false
      }
    },

    markedAsMasterEntity() {
      // this handles when masterEntity is not known
      if (
        !isNull(this.markedAsMasterEntity) &&
        this.masterEntity === this.markedAsMasterEntity
      ) {
        this.isUnSubscribed = false
      }
    }
  },
  mounted() {
    this.isUnSubscribed = !this.entity.isSubscribed
  },
  methods: {
    onRadioInput() {
      this.$emit('markAsMaster', this.entity.sgaId)
    },

    onCheckboxInput(isUnSubscribed) {
      this.isUnSubscribed = isUnSubscribed
    }
  }
}
</script>

<style lang="scss" scoped>
.corporate-action-row {
  width: 100%;
  display: contents;
  font-size: 13px;
  font-weight: 600;

  td {
    text-align: left;
    padding: 8px;
    font-family: Quicksand;
    color: var(--secondary-text-color);

    .common-height {
      height: 50px;
      display: flex;
      align-items: center;
    }

    .center-column-data {
      justify-content: center;
    }
  }
}
</style>
