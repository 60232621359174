export const pmDashboardColumns = [
  {
    title: 'Entity Details',
    children: [
      {
        field: 'selected',
        width: '45px',
        columnMenu: false,
        groupable: false,
        headerSelectionValue: false
      },
      {
        field: 'sgaId',
        title: 'SGA ID',
        width: '175',
        minResizableWidth: '175',
        cell: 'viewEntityTemplate',
        groupable: false
      },
      {
        field: 'requestId',
        title: 'Request ID',
        width: '170',
        minResizableWidth: '150',
        groupable: false
      },
      {
        field: 'legalEntityName',
        title: 'Legal Entity Name',
        width: '200px',
        groupable: false,
        cell: 'legalEntityTemplate'

      },
      {
        field: 'countryDOMName',
        title: 'Country Of Domicile',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        width: 240,
        groupable: false
      },
      {
        field: 'ultimateParentName',
        title: 'Ultimate Parent Name',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        groupable: true,
        width: 270
      },
      {
        field: 'lei',
        title: 'LEI',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        groupable: false,
        width: 180
      },
      {
        field: 'lastUpdated',
        title: 'Last Updated'
      },
      {
        field: 'isSubscribed',
        title: 'Challenge',
        className: 'subscription-class',
        cell: 'subscriptionDropDownTemplate',
        groupable: false,
        width: 150
      },
      {
        field: 'workType',
        title: 'Work Type',
        groupable: false,
        width: '300'
      }
    ]
  },
  {
    title: 'Assignments',
    children: [
      {
        field: 'assignedTo',
        title: 'Assigned To',
        width: 200,
        groupable: false
      },
      {
        field: 'status',
        title: 'Status',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        groupable: true,
        width: 200
      },
      {
        field: 'priority',
        title: 'Priority',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        groupable: true,
        width: 200
      }
    ]
  }
]
