<template>
  <div>
    <k-dialog
      v-if="visibleDialog"
      :title="'Subscription Info'"
      :minWidth="500"
      @close="toggleDialog">
      <div class="dialog-container">
        <div class="dialog-info-row">
          <div class="dialog-content-column">
            <span>SGA ID : {{ sgaId }}</span>
          </div>
          <div class="dialog-content-column">
            <span>Legal Entity Name: {{ legalEntityName }}</span>
          </div>
          <div class="dialog-content-column">
            <span>Request ID: {{ requestId }}</span>
          </div>
        </div>
        <div class="dialog-content-row">
          <SearchableSelect
            :value="declinedReason"
            label="Decline Reason"
            :placeholder="'Select decline reason'"
            :data="declinedReasonsList"
            value_field="value"
            text_field="text"
            @onChangeName="handleChangeDeclineReason"
            class="form-input-field"
            :isMandatory="true"
            :mandatorySubmit="submitted"
            :submitted="submitted" />
        </div>
      </div>
      <dialog-actions-bar>
        <div class="action-buttons">
          <b-button
            class="action-button cancel-button"
            @click="toggleDialog"
            :disabled="isDialogInUpdateState">Cancel</b-button>
          <b-button
            class="action-button confirm-button"
            @click="handleUpdateSubscription"
            :disabled="isDialogInUpdateState"
            :loading="isDialogInUpdateState">Update</b-button>
        </div>
      </dialog-actions-bar>
    </k-dialog>
  </div>
</template>
<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs'
import SearchableSelect from '@/components/DMP/SearchableSelect'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    'k-dialog': Dialog,
    'dialog-actions-bar': DialogActionsBar,
    SearchableSelect
  },
  props: {
    showDialog: {
      type: Boolean
    },
    declinedReasons: {
      type: Array,
      default: () => []
    },
    sgaId: {
      type: String,
      default: () => ''
    },
    legalEntityName: {
      type: String,
      default: () => ''
    },
    requestId: {
      type: String,
      default: () => ''
    }
  },
  data: function () {
    return {
      visibleDialog: this.showDialog,
      subscriptionValue: null,
      declinedReason: null,
      declinedReasonsList: this.declinedReasons,
      submitted: false,
      isDialogInUpdateState: this.isDialogInUpdate
    }
  },
  computed: {
    ...mapState('pmDashboard', ['isDialogInUpdate'])
  },
  watch: {
    showDialog (newVal) {
      this.visibleDialog = newVal
    },
    declinedReasons (newVal) {
      this.declinedReasonsList = newVal
    },
    isDialogInUpdate (newVal) {
      this.isDialogInUpdateState = newVal
    }
  },
  methods: {
    ...mapActions('pmDashboard', ['updateSubscription', 'getpmDashboardData']),
    toggleDialog () {
      this.$emit('update:showDialog', !this.visibleDialog)
      this.visibleDialog = !this.visibleDialog
      this.resetSubscriptionValue()
    },
    handleChangeDeclineReason (event) {
      this.declinedReason = event
    },
    async handleUpdateSubscription () {
      // Update case for false
      this.submitted = true
      if (this.declinedReason !== null) {
        const payload = {
          sgaId: this.sgaId,
          isSubscribed: false,
          declineId:
            this.declinedReason !== null ? this.declinedReason.value : null
        }
        const responseStatus = await this.updateSubscription(payload)
        if (responseStatus && responseStatus === 200) {
          this.$emit('update-value', false)
          this.toggleDialog()
          await this.getpmDashboardData()
        } else {
          this.toggleDialog()
        }
      }
    },
    resetSubscriptionValue () {
      this.declinedReason = null
      this.$emit('reset-subscription')
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .k-window-content,
.k-prompt-container {
  overflow: visible;
}
.dialog-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .dialog-content-row {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .form-input-field {
      width: 100%;
      .k-picker-outline {
        border: none;
      }
    }
  }
  .dialog-info-row {
    .dialog-content-column {
      margin-bottom: 0.5rem;
    }
  }
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 5rem;
  // padding-right: 3.875rem;
  // margin-bottom: 36px;
}
.action-button {
  color: #424242;
  font-size: 16px;
  font-weight: 600;
  min-width: 104px;
  // height: 46px;
}
.confirm-button {
  background-color: #535eeb; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */
  border-radius: 8px;
  padding: 8px;
  opacity: 1;
  text-align: left;
  font-size: 16px;
  line-height: 17px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-left: 1rem;
}
.confirm-button:hover {
  background-color: #00218a; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */

  opacity: 0.9;
}

.cancel-button {
  background-color: #cccccc; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */

  border-radius: 8px;
  padding: 8px;
  opacity: 1;
  text-align: center;
  font-size: 16px;
  line-height: 17px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: #444444;
  opacity: 1;
}
.cancel-button:hover {
  background-color: #cccccc; /* Color of the background */
  background-position-x: 0%; /* Horizontal position of the background image */
  background-position-y: 0%; /* Vertical position of the background image */
  background-repeat: no-repeat; /* Background image will not repeat */
  background-origin: padding-box; /* Background painting area is the padding box */
  opacity: 0.9;
}
</style>
