<template>
  <div>
    <!-- <button @click="togglePopup">Open Popup</button> -->
    <dropdown-wrap-vue @onClickOutside="closePopup">
      <b-button
        class="set-assignees-button"
        size="is-small"
        @click="togglePopup"
        @mouseover="textPopup"
        @mouseleave="closeTextPopup"
        ref="button">
        <b-icon
          v-if="dialogName === 'Select Team'"
          icon="account-multiple"
          size="is-small"
          class="toolbar-icons-set-assignees"></b-icon>
        <b-icon
          v-if="dialogName === 'Select Assignee'"
          icon="account-circle-outline"
          size="is-small"
          class="toolbar-icons-set-assignees"></b-icon>
      </b-button>
      <div v-if="showTextPopup" class="dialog-name">
        <p :class="dialogName === 'Select Team' ? 'team-text' : 'team-memeber'">
          {{ dialogName }}
        </p>
      </div>
      <div v-if="showPopup" class="popup">
        <div class="popup-content">
          <k-input
            :placeholder="'Search'"
            :inputPrefix="'prefix'"
            v-model="searchQuery">
            <template v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
          <div v-if="filteredTeamMembers && filteredTeamMembers.length > 0">
            <div class="checkbox-container">
              <div class="checkbox">

                <input
                  type="checkbox"
                  v-model="selectAllFields"
                  @change="selectAllOptions()" />
                <span>Select All</span>
              </div>
            </div>
            <div
              class="checkbox-container"
              v-for="(team, index) in filteredTeamMembers"
              :key="index">
              <div class="checkbox">
                <input
                  type="checkbox"
                  v-model="selectedOptions"
                  :value="team" />
                <span>{{ team.name }}</span>
              </div>
            </div>
          </div>
          <div class="no-data-container" v-else>No data found</div>
        </div>
      </div>
    </dropdown-wrap-vue>
  </div>
</template>

<script>
import { Input } from '@progress/kendo-vue-inputs'
import DropdownWrapVue from '@/components/Common/DropdownWrap.vue'

export default {
  components: {
    'k-input': Input,
    DropdownWrapVue
  },
  props: {
    listData: {
      type: Array
    },
    filterDashboardData: {
      type: Function
    },
    dialogName: {
      type: String
    },
    selectAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showPopup: false,
      searchQuery: '',
      selectedOptions: [],
      showTextPopup: false,
      selectAllFields: this.selectAll
    }
  },
  computed: {
    filteredTeamMembers () {
      // Filter team members based on the search query
      return this.listData.filter((team) =>
        `${team.name}`.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
    }
  },
  watch: {
    // Watch for changes in selectedOptions and update selectAllFields accordingly
    selectedOptions: {
      handler () {
        this.selectAllFields =
          this.selectedOptions.length === this.listData.length
        this.$emit('filterDashboardData', this.selectedOptions)
      },
      deep: true
    }
  },
  methods: {
    togglePopup () {
      this.showPopup = !this.showPopup
      this.showTextPopup = !this.showTextPopup
    },
    textPopup () {
      this.showTextPopup = true
    },
    closeTextPopup () {
      this.searchQuery = ''
      this.showTextPopup = false
    },
    closePopup () {
      this.searchQuery = ''
      this.showPopup = false
    },
    selectAllOptions () {
      if (this.selectAllFields) {
        this.selectedOptions = this.listData
        this.$emit('filterDashboardData', this.selectedOptions)
      } else {
        // Unselect all options
        this.selectedOptions = []
        this.$emit('filterDashboardData', null)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  z-index: 999;
}

.popup-content {
  background-color: #fff;
  width: 180px;
  height: auto;
  max-height: 250px;
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.k-input,
.k-picker {
  width: 95%;
  margin-left: 2%;
}

.no-data-container {
  text-align: left;
  font-family: Quicksand;
  font-size: 13px;
  padding: 2px;
  font-weight: 400;
  letter-spacing: 0.16px;
  color: #000000;
  opacity: 1;
  margin-left: 4px;
}
.dialog-name {
  display: flex;
  align-items: center;
  position: absolute;
  top: 37px;
  .team-text {
    font-family: Quicksand;
    font-size: 13px;
    padding: 2px;
    letter-spacing: 0.16px;
    color: #00218a;
    opacity: 1;
    margin-left: -20px;
  }
  .team-memeber {
    font-family: Quicksand;
    font-size: 13px;
    padding: 2px;
    letter-spacing: 0.16px;
    color: #00218a;
    opacity: 1;
    margin-left: -30px;
  }
}
.checkbox-container {
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;

  span {
    text-align: left;
    font-family: Quicksand;
    font-size: 13px;
    padding: 2px;
    font-weight: 400;
    letter-spacing: 0.16px;
    color: #000000;
    opacity: 1;
    margin-left: 4px;
  }

  .checkbox {
    padding: 2px;
    display: flex;
    padding-right: 4px;
    padding-left: 4px;
  }
}
.checkbox-container:hover {
  background: rgb(204, 204, 207);
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .popup-content {
    position: absolute;
    top: -5px;
    left: 0px;
  }
}
@media (min-width: 1700px) {
  .popup-content {
    position: absolute;
    top: -10px;
    left: 0px;
  }
}
</style>
