var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-grid-container"},[_c('div',{staticClass:"grid-toolbar"},[_vm._m(0),_c('div',{staticClass:"grid-toolbar-filters"},[_c('span',{staticClass:"dropdown-container"},[_c('MultiSelectorVue',{attrs:{"label":"Work Type","placeholder":"","selected":_vm.selectedWorkTypes,"list":_vm.workTypes},on:{"onChange":_vm.onChangeWorkTypeSelection}})],1),_c('span',{staticClass:"k-textbox k-grid-search k-display-flex"},[_c('k-input',{style:({ width: '430px' }),attrs:{"value":_vm.searchWord,"placeholder":'Search by Entity Name or SGA ID or Request ID',"inputPrefix":'prefix'},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleInputChange.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('span',{staticClass:"k-input-icon k-icon k-i-search"})]},proxy:true}]),model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}})],1)])]),_c('div',{staticClass:"production-status"},[_c('div',{staticClass:"name"},[_vm._v("Production Status")]),_c('div',{staticClass:"expand-collapse-icon",on:{"click":_vm.toggleExpandCollapse}},[_vm._v(" "+_vm._s(_vm.expanded ? 'Collapse' : 'Expand')+" "),_c('SvgIcon',{attrs:{"icon":_vm.expanded ? _vm.chevronUpIcon : _vm.chevronDownIcon,"size":'medium'}})],1)]),(_vm.expanded)?_c('div',[_c('div',{style:({
        display: 'grid',
        gridTemplateColumns: _vm.gridColumnTemplate,
        justifyContent: 'flex-start',
        marginRight: '10px'
      })},[_vm._l((_vm.filteredItems),function(item,index){return [(!_vm.isCardHidden(index))?_c('div',{key:index,class:{
            'pm-card': !_vm.isSeventhCard(index) && !_vm.isCardHidden(index),
            'seventh-pm-card': _vm.isSeventhCard(index)
          },style:({
            marginRight: index !== 5 && !_vm.isSeventhCard(index) ? '10px' : '0'
          }),on:{"click":function($event){return _vm.handleCardClick(index)},"mouseover":function($event){return _vm.handleMouseOver($event, index)},"mouseleave":function($event){return _vm.handleMouseLeave($event, index)}}},[(!_vm.isSeventhCard(index))?[_c('div',{staticClass:"pm-card-header"},[_c('SvgIcon',{staticClass:"icon",attrs:{"icon":_vm.clockIcon,"size":'medium'}}),_c('span',{staticClass:"pm-title"},[_vm._v(_vm._s(item.title))])],1),_c('hr',{staticClass:"divider"}),_c('div',{staticClass:"pm-card-body"},[_c('div',{staticClass:"body-content"},[_c('span',[_vm._v(_vm._s(item.text))]),(_vm.showArrow === index)?_c('SvgIcon',{staticClass:"icon",attrs:{"icon":_vm.arrowRightIcon,"size":'6x'}}):_vm._e()],1)])]:(_vm.isSeventhCard(index) && _vm.isCardAdded)?[_c('div',{staticClass:"column"},[_c('div',{staticClass:"pm-title"},[_vm._v("High")]),_c('div',{staticClass:"body-text"},[_vm._v(" "+_vm._s(_vm.entitiesOverview?.entitiesHigh || 0)+" ")])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"pm-title"},[_vm._v("Medium")]),_c('div',{staticClass:"body-text"},[_vm._v(" "+_vm._s(_vm.entitiesOverview?.entitiesMedium || 0)+" ")])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"pm-title"},[_vm._v("Low")]),_c('div',{staticClass:"body-text"},[_vm._v(" "+_vm._s(_vm.entitiesOverview?.entitiesLow || 0)+" ")])])]:_vm._e()],2):_vm._e()]})],2)]):_vm._e(),_c('div',[_c('PMDashboardGrid',{ref:"pmDashboardComponent",attrs:{"selected-work-types-props":_vm.selectedWorkTypes}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-toolbar-title"},[_c('p',[_vm._v("Production Overview")])])
}]

export { render, staticRenderFns }