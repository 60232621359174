<template>
  <div class="data-grid-container">
    <div class="grid-toolbar">
      <div class="grid-toolbar-title">
        <p>Production Overview</p>
      </div>
      <div class="grid-toolbar-filters">
        <span class="dropdown-container">
          <MultiSelectorVue
            label="Work Type"
            placeholder=""
            :selected="selectedWorkTypes"
            @onChange="onChangeWorkTypeSelection"
            :list="workTypes" />
        </span>
        <span class="k-textbox k-grid-search k-display-flex">
          <k-input
            :style="{ width: '430px' }"
            :value="searchWord"
            v-model="searchWord"
            :placeholder="'Search by Entity Name or SGA ID or Request ID'"
            :inputPrefix="'prefix'"
            @keyup.enter="handleInputChange">
            <template v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
        </span>
      </div>
    </div>
    <div class="production-status">
      <div class="name">Production Status</div>
      <div class="expand-collapse-icon" @click="toggleExpandCollapse">
        {{ expanded ? 'Collapse' : 'Expand' }}
        <SvgIcon
          :icon="expanded ? chevronUpIcon : chevronDownIcon"
          :size="'medium'" />
      </div>
    </div>
    <div v-if="expanded">
      <div
        :style="{
          display: 'grid',
          gridTemplateColumns: gridColumnTemplate,
          justifyContent: 'flex-start',
          marginRight: '10px'
        }">
        <template v-for="(item, index) in filteredItems">
          <div
            :key="index"
            v-if="!isCardHidden(index)"
            :class="{
              'pm-card': !isSeventhCard(index) && !isCardHidden(index),
              'seventh-pm-card': isSeventhCard(index)
            }"
            @click="handleCardClick(index)"
            @mouseover="handleMouseOver($event, index)"
            @mouseleave="handleMouseLeave($event, index)"
            :style="{
              marginRight: index !== 5 && !isSeventhCard(index) ? '10px' : '0'
            }">
            <template v-if="!isSeventhCard(index)">
              <div class="pm-card-header">
                <SvgIcon :icon="clockIcon" :size="'medium'" class="icon" />
                <span class="pm-title">{{ item.title }}</span>
              </div>
              <hr class="divider" />
              <div class="pm-card-body">
                <div class="body-content">
                  <span>{{ item.text }}</span>
                  <SvgIcon
                    :icon="arrowRightIcon"
                    :size="'6x'"
                    class="icon"
                    v-if="showArrow === index" />
                </div>
              </div>
            </template>
            <template v-else-if="isSeventhCard(index) && isCardAdded">
              <div class="column">
                <div class="pm-title">High</div>
                <div class="body-text">
                  {{ entitiesOverview?.entitiesHigh || 0 }}
                </div>
              </div>
              <div class="column">
                <div class="pm-title">Medium</div>
                <div class="body-text">
                  {{ entitiesOverview?.entitiesMedium || 0 }}
                </div>
              </div>
              <div class="column">
                <div class="pm-title">Low</div>
                <div class="body-text">
                  {{ entitiesOverview?.entitiesLow || 0 }}
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div>
      <PMDashboardGrid
        :selected-work-types-props="selectedWorkTypes" 
        ref="pmDashboardComponent" />
    </div>
  </div>
</template>

<script>
import { SvgIcon } from '@progress/kendo-vue-common'
import {
  clockIcon,
  arrowRightIcon,
  chevronDownIcon,
  chevronUpIcon
} from '@progress/kendo-svg-icons'
import { Input } from '@progress/kendo-vue-inputs'
import MultiSelectorVue from '@/components/Dropdowns/MultiSelector.vue'
import { mapActions, mapState } from 'vuex'
import PMDashboardGrid from './PMDashboard/PMDashboardGrid.vue'
import { userRoles } from '@/util/permissions.utils'

const DEFAULT_WORk_TYPE = [
  { name: 'Corporate Action', id: 'corporateAction' },
  { name: 'Data Enrichment', id: 'dataEnrichment' },
  { name: 'New Onboarding', id: 'newOnBoarding' },
  { name: 'Periodic Review', id: 'periodicReview' }
]

export default {
  name: 'DMPDashboard',
  components: {
    SvgIcon,
    'k-input': Input,
    MultiSelectorVue,
    PMDashboardGrid
  },
  data () {
    return {
      clockIcon,
      arrowRightIcon,
      chevronDownIcon,
      chevronUpIcon,
      items: [],
      gridColumnTemplate: 'repeat(6, 1fr)',
      isCardAdded: false,
      showArrow: null,
      expanded: true,
      selectedWorkTypes: DEFAULT_WORk_TYPE,
      workTypes: DEFAULT_WORk_TYPE,
      searchWord: '',
      userRoles
    }
  },
  computed: {
    ...mapState('assignedEntities', ['entitiesOverview']),
    ...mapState('user', ['userDetails']),
    filteredItems () {
      return this.items.slice(0, 7)
    }
  },
  watch: {
    entitiesOverview: {
      handler (newVal) {
        this.updateItems(newVal)
      },
      deep: true,
      immediate: true
    }
  },
  async mounted () {
    this.loading = true
    const isPMRole = this.userRoles.PM_MANAGER === this.userDetails.roleName
    await this.getEntityDetailOverView(isPMRole)
    this.loading = false
  },
  methods: {
    ...mapActions('assignedEntities', ['getEntityDetailOverView']),
    isCardHidden (index) {
      return this.isCardAdded && index === 0
    },
    shouldHaveGap () {
      return this.isCardAdded && this.filteredItems.length > 6
    },

    updateItems (entitiesOverview) {
      this.items = [
        {
          title: 'Total Entities',
          text: entitiesOverview?.totalEntities || 0
        },
        {
          title: 'Entities To do',
          text: entitiesOverview?.entitiesTodo || 0
        },
        {
          title: 'Entities WIP',
          text: entitiesOverview?.entitiesInProgress || 0
        },
        {
          title: 'Entities in Review',
          text: entitiesOverview?.entitiesInReview || 0
        },
        {
          title: 'Corporate Action',
          text: entitiesOverview?.corporateActionCount || 0
        },
        {
          title: 'New Onboarding',
          text: entitiesOverview?.newOnboardingCount || 0
        }
      ]
    },
    toggleExpandCollapse () {
      this.expanded = !this.expanded
    },
    handleCardClick (index) {
      if (index === 5) {
        if (!this.isCardAdded) {
          this.addSeventhCard()
        } else {
          this.removeSeventhCard()
        }
      }
    },
    handleMouseOver (event, index) {
      if (index === 5) {
        this.showArrow = index
      }
    },
    handleMouseLeave () {
      this.showArrow = null
    },
    isSeventhCard (index) {
      return index === 6
    },
    addSeventhCard () {
      this.items.push('Column 7')
      this.updateGridTemplate(6)
      this.isCardAdded = true
    },
    removeSeventhCard () {
      this.items.pop()
      this.updateGridTemplate(6)
      this.isCardAdded = false
    },
    updateGridTemplate (value) {
      this.gridColumnTemplate = `repeat(${value}, 1fr)`
    },
    handleInputChange () {
      this.$refs.pmDashboardComponent.globalSearchValue(this.searchWord)
    },
    onChangeWorkTypeSelection (value) {
      this.selectedWorkTypes = value
      this.$refs.pmDashboardComponent.workTypeFilter(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.data-grid-container {
  margin: 25px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .grid-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
    border: none;

    .grid-toolbar-title {
      p {
        text-align: left;
        font-weight: 600;
        font-style: normal;
        font-size: 28px;
        line-height: 15px;
        font-family: Quicksand;
        letter-spacing: 0px;
        color: #00218a;
        opacity: 1;
        line-height: 1.5rem;
      }
    }

    .grid-toolbar-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .export-button {
        margin-right: 10px;
      }

      .export-csv-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 8px;
        color: #363636;
        border: solid 1px grey;
        border-radius: 4px;
        cursor: pointer;
        text-decoration: none;
        height: 36px;
        transition: background-color 0.3s ease;
        font-size: 14px;
        min-width: 150px;
        font-weight: bold;
      }

      .export-csv-button:hover {
        background-color: #5c5a5a;
        color: #ffffff;
      }

      .export-icon {
        margin-right: 5px;
      }

      .dropdown-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.6rem;
        width: 100%;

        ::v-deep .data-source-container {
          display: flex;
          align-items: center;

          .top-label {
            font-size: 14px;
            line-height: 20px;
            font-family: Quicksand;
            font-weight: 600;
            font-style: normal;
            color: #7d7d7d;
            margin-right: 0.6rem;
            margin-top: 0.3rem;
          }
          .dropdown-wrap {
            .data-source-header {
              background-color: #ffffff;
              border: 1px solid #cccccc;
              .button-container {
                .ellipsis {
                  color: black;
                  font-size: 14px;
                  line-height: 20px;
                  font-family: Quicksand;
                  font-weight: 600;
                  font-style: normal;
                }
                svg path {
                  stroke-width: 1;
                  stroke: black;
                }
              }
            }

            .data-source-abs {
              background: #ffffff 0% 0% no-repeat padding-box;
              color: #000000;

              .custom-checkbox {
                p {
                  color: #000000;
                }
              }

              .custom-checkbox:hover {
                background: rgba(196, 200, 248, 0.3) 0% 0% no-repeat padding-box;
              }
            }
          }
        }
      }

      ::v-deep .k-grid-search {
        height: 36px;
        width: 100%;
        .k-input {
          border: 1px solid #cccccc;

          input {
            // height: 44px;
            font-size: 13px;
            line-height: 20px;
            font-weight: 600;
            font-family: Quicksand;
            font-style: normal;
          }

          .k-input-inner {
            margin-left: 0.1rem;
          }

          .k-input-prefix {
            margin-left: 0.5rem;
            color: #cccccc;
          }
        }
      }
    }
  }
  .production-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px;
    background-color: #f2f2f2;
    margin-bottom: 5px;
  }

  .name {
    font-size: 22px;
    color: #1e1e1e;
  }

  .expand-collapse-icon {
    font-size: 16px;
    color: #1e1e1e;
    cursor: pointer;
  }
}

.hide-pm-card {
  display: none;
}

.pm-card {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(168, 216, 245, 1) 0%,
    rgba(239, 185, 240, 1) 100%
  );
  color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  transition: background 0.3s;
}

.pm-card:hover {
  background: rgb(2, 0, 36);
  color: #ffffff;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(99, 216, 243, 1) 0%,
    rgba(122, 77, 238, 1) 100%
  );
}

.pm-card .pm-card-header svg {
  fill: #535eeb;
}

.pm-card:hover .pm-card-header svg {
  fill: #ffffff;
}

.pm-card:hover .pm-card-body svg {
  fill: #ffffff;
}

.pm-card:hover .pm-card-body .body-content {
  color: #ffffff;
}

.pm-card-header {
  -webkit-box-shadow: unset;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.pm-title {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: bold;
}

.pm-card:hover .pm-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}

.divider {
  margin: 5px;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.body-content {
  font-size: 46px;
  color: #535eeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.seventh-pm-card {
  display: block;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  background-color: #dfdfdf;
  padding: 10px;
}

.column {
  padding: 2px;
  border-radius: 5px;
}

.body-text {
  color: #535eeb;
  font-size: 46px;
  margin-top: 5px;
}
</style>
